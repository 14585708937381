import { useMemo } from 'react';
import { toHex } from 'viem';
import { useAccount as useEVMAccount, useChainId } from 'wagmi';
import { useAccount as useFuelAccount } from '@fuels/react';
import { useTonAddress } from '@tonconnect/ui-react';
import { useStarknetAccount } from '@/starknet/hooks/account';
import { useWallet as useSolanaWallet } from '@solana/wallet-adapter-react';
import { NetworkTypes } from '@/providers/web3Provider';
import { INetwork } from '@/types/apiTypes';
import { useWallet as useTronWallet } from '@tronweb3/tronwallet-adapter-react-hooks';
import { useTronChainId } from '@/tron/hooks/useTronChainId';
import { chainIdNetworkMap } from '@tronweb3/tronwallet-adapters';
import { useBitcoinWallet } from '@/bitcoin/hooks/useBitcoinWallet';
import { useSuiWallet } from '@/sui/hooks/useSuiWallet';
import { SuiMainnetChain, UnknownChain } from '@suiet/wallet-kit';

export const useWalletFrom = (network: INetwork | undefined) => {
  const evmChainId = useChainId();

  const tonAddress = useTonAddress();
  const { address: evmAddress } = useEVMAccount();
  const { publicKey: solanaAddress, wallet: solanaWallet } = useSolanaWallet();
  const { address: starknetAddress, chainId: starknetChainId } =
    useStarknetAccount();
  const { address: tronAddress } = useTronWallet();
  const { account: fuelAddress } = useFuelAccount();
  const { address: bitcoinAddress } = useBitcoinWallet();
  const { address: suiAddress, chain: suiChain } = useSuiWallet();

  const tronChainId = useTronChainId();

  const isFromStarknet = network?.network_type === NetworkTypes.STARKNET;
  const isFromTon = network?.network_type === NetworkTypes.TON;
  const isFromSolana = network?.network_type === NetworkTypes.SOLANA;
  const isFromTron = network?.network_type === NetworkTypes.TRON;
  const isFromFuel = network?.network_type === NetworkTypes.FUEL;
  const isFromBitcoin = network?.network_type === NetworkTypes.BITCOIN;
  const isFromSui = network?.network_type === NetworkTypes.SUI;

  const isFromEVM =
    !isFromStarknet &&
    !isFromTon &&
    !isFromSolana &&
    !isFromTron &&
    !isFromFuel &&
    !isFromBitcoin &&
    !isFromSui;

  const isEclipseConnected =
    network?.name.toLowerCase() === 'eclipse' &&
    !!solanaAddress &&
    solanaWallet?.adapter.name.toLowerCase() === 'backpack';

  const isSolanaConnected =
    network?.name.toLowerCase() === 'solana'
      ? !!solanaAddress
      : isEclipseConnected;

  const isFromConnected =
    (isFromStarknet && !!starknetAddress) ||
    (isFromTon && !!tonAddress) ||
    (isFromSolana && !!isSolanaConnected) ||
    (isFromTron && !!tronAddress) ||
    (isFromFuel && !!fuelAddress) ||
    (isFromSui && !!suiAddress) ||
    (isFromEVM && !!evmAddress) ||
    (isFromBitcoin && !!bitcoinAddress);

  const walletFrom = useMemo(() => {
    switch (network?.network_type) {
      case NetworkTypes.EVM:
        return evmAddress;
      case NetworkTypes.STARKNET:
        return starknetAddress;
      case NetworkTypes.TON:
        return tonAddress;
      case NetworkTypes.SOLANA:
        return solanaAddress?.toBase58();
      case NetworkTypes.TRON:
        return tronAddress || undefined;
      case NetworkTypes.FUEL:
        return fuelAddress || undefined;
      case NetworkTypes.BITCOIN:
        return bitcoinAddress;
      case NetworkTypes.SUI:
        return suiAddress;
      default:
        return evmAddress;
    }
  }, [
    evmAddress,
    starknetAddress,
    tonAddress,
    solanaAddress,
    tronAddress,
    fuelAddress,
    bitcoinAddress,
    suiAddress,
    network?.network_type,
  ]);

  const isWrongNetwork = useMemo(() => {
    if (!network) {
      return true;
    }

    if (network.network_type === NetworkTypes.TON) return false;
    if (network.network_type === NetworkTypes.SOLANA) return false;
    if (network.network_type === NetworkTypes.FUEL) return false;
    if (network.network_type === NetworkTypes.BITCOIN) return false;

    if (network.network_type === NetworkTypes.STARKNET)
      return !starknetChainId || toHex(starknetChainId) !== network.chainId;

    if (network.network_type === NetworkTypes.TRON && tronChainId) {
      return tronChainId !== Object.keys(chainIdNetworkMap)?.[0];
    }

    if (network.network_type === NetworkTypes.SUI && suiChain?.id) {
      return (
        suiChain?.id !== SuiMainnetChain.id || suiChain.id === UnknownChain.id
      );
    }

    if (!evmChainId) return true;
    return evmChainId !== +network.chainId;
  }, [evmChainId, starknetChainId, tronChainId, suiChain, network]);

  return { isFromConnected, walletFrom, isWrongNetwork };
};
