import { getHttpEndpoint } from '@orbs-network/ton-access';
import { Address, beginCell, toNano } from '@ton/core';
import { JettonMaster, TonClient } from '@ton/ton';
import { useTonConnectUI } from '@tonconnect/ui-react';
import { ethers } from 'ethers';
import { useCallback } from 'react';
import { Contract, uint256 } from 'starknet';
import { useAccount as useEVMAccount } from 'wagmi';
import {
  useConnection,
  useWallet as useSolanaWallet,
} from '@solana/wallet-adapter-react';

import * as solana from '@solana/web3.js';
import * as slp from '@solana/spl-token';

import { useStarknetAccount } from '@/starknet/hooks/account';
import { isValidTonAddress } from '@/ton/utils';
import { EthersError } from '@/types/ethers';
import { Erc20Contract } from '../evm/contracts/erc20Contract.service';
import { NetworkTypes } from '../providers/web3Provider';
import { balanceABIFragment } from '../starknet';
import { useAppStore } from '../stores/app.store';
import { ICurrency, INetwork } from '../types/apiTypes';
import { logInGroup } from '../utils';
import { getRPCByNetwork } from '../utils/getRpcUrl';
import { useEVMSigner } from './useSigner';
import { useWallet as useTronWallet } from '@tronweb3/tronwallet-adapter-react-hooks';
import { tronWeb } from '@/tron/providers/TronProvider';
import { Transaction } from '@tronweb3/tronwallet-abstract-adapter';
import { waitForTransaction as waitForTronTransaction } from '@/tron/utils/waitForTransaction';
import { TRON_FEE_LIMIT, ZERO_TRX_IN_SUN_UNITS } from '@/tron/constants';
import { useFuelWallet } from '@/fuel/hooks/useFuelWallet';
import { useBitcoinWallet } from '@/bitcoin/hooks/useBitcoinWallet';
import { useSuiWallet } from '@/sui/hooks/useSuiWallet';
import { Connection } from '@solana/web3.js';

export interface WithdrawParams {
  sendedCurrency: ICurrency;
  sendedAmount: number;
  network?: INetwork;
  walletReceiver: string;
}

export const useWithdraw = () => {
  const { address: account } = useEVMAccount();
  const { account: starknetAccount, connector: starknetConnector } =
    useStarknetAccount();
  const { wallet: solanaWallet, sendTransaction: sendSolanaTransaction } =
    useSolanaWallet();
  const solanaConnection = useConnection();
  const [tonconnect] = useTonConnectUI();
  const signer = useEVMSigner();
  const { signTransaction, address: tronAddress } = useTronWallet();

  const setIsCancelled = useAppStore(s => s.setIsCancelled);

  const { sendTransaction: sendFuelTransaction } = useFuelWallet();
  const { sendTransaction: sendBitcoinTransaction } = useBitcoinWallet();
  const { sendTransaction: sendSuiTransaction } = useSuiWallet();

  const withdrawErc20 = useCallback(
    async (params: Required<WithdrawParams>) => {
      const { sendedCurrency, sendedAmount, walletReceiver } = params;
      if (!signer) throw Error('Can not retrieve signer');
      const instance = new Erc20Contract(
        sendedCurrency.contract.address,
        signer
      );
      const tx = await instance.transfer(
        walletReceiver,
        ethers.utils.parseUnits(`${sendedAmount}`, sendedCurrency.decimals)
      );

      const res = await tx?.wait();
      return res;
    },
    [signer]
  );

  const withdrawNative = useCallback(
    async (params: Required<WithdrawParams>) => {
      const { sendedAmount, walletReceiver } = params;
      if (!account) {
        throw new Error('No address');
      }
      if (!signer) throw Error('Can not retrieve signer');

      const withdrawedAmount = ethers.utils.parseEther(`${sendedAmount}`);

      const otpions = {
        from: account,
        to: walletReceiver,
        value: withdrawedAmount,
      };
      const tx = await signer.sendTransaction(otpions);
      return await tx.wait(1);
    },
    [account, signer]
  );

  const withdrawEvm = useCallback(
    async (params: Required<WithdrawParams>) => {
      const { sendedCurrency, walletReceiver, sendedAmount, network } = params;

      if (sendedCurrency.contract.address === ethers.constants.AddressZero) {
        return await withdrawNative({
          walletReceiver,
          sendedAmount,
          sendedCurrency,
          network,
        });
      } else {
        return await withdrawErc20({
          walletReceiver,
          sendedAmount,
          sendedCurrency,
          network,
        });
      }
    },
    [withdrawNative, withdrawErc20]
  );

  const withdrawStarknet = useCallback(
    async (params: Required<WithdrawParams>) => {
      const { sendedCurrency, walletReceiver, sendedAmount } = params;

      if (!starknetAccount) {
        throw new Error('No starknet account');
      }

      const erc20instance = new Contract(
        balanceABIFragment,
        sendedCurrency.contract.address,
        starknetAccount
      );

      const wei = ethers.utils.parseUnits(
        sendedAmount.toString(),
        sendedCurrency.decimals
      );

      const amountBn = uint256.bnToUint256(wei.toBigInt());
      const contractTx = erc20instance.populate('transfer', {
        recipient: walletReceiver,
        amount: amountBn,
      });

      if (starknetConnector?.id === 'argentWebWallet') {
        return await new Promise((res, rej) => {
          setTimeout(async () => {
            try {
              const sendedTx = await starknetAccount.execute(contractTx);

              res(
                await starknetAccount.waitForTransaction(
                  sendedTx.transaction_hash
                )
              );
            } catch (error) {
              rej(error);
            }
          }, 1000);
        });
      } else {
        const sendedTx = await starknetAccount.execute(contractTx);

        return await starknetAccount.waitForTransaction(
          sendedTx.transaction_hash
        );
      }
    },
    [starknetAccount, starknetConnector?.id]
  );

  const withdrawTon = useCallback(
    async (params: Required<WithdrawParams>) => {
      const { network, sendedCurrency, walletReceiver, sendedAmount } = params;
      const isJettonTransfer = isValidTonAddress(
        sendedCurrency.contract.address
      );

      if (isJettonTransfer) {
        const rpcItem = await getRPCByNetwork(network);
        let endpoint = rpcItem.rpc;

        if (endpoint === 'mainnet') {
          endpoint = await getHttpEndpoint({ network: 'mainnet' });
        } else if (endpoint === 'testnet')
          endpoint = await getHttpEndpoint({ network: 'testnet' });

        const client = new TonClient({ endpoint });
        const jettonMaster = client.open(
          JettonMaster.create(Address.parse(sendedCurrency.contract.address))
        );
        const jettonWalletAddress = await jettonMaster.getWalletAddress(
          Address.parse(tonconnect.connector.account?.address || '')
        );
        const body = beginCell()
          .storeUint(0xf8a7ea5, 32)
          .storeUint(0, 64)
          .storeCoins(sendedAmount * 10 ** sendedCurrency.decimals)
          .storeAddress(Address.parse(walletReceiver))
          .storeAddress(
            Address.parse(tonconnect.connector.account?.address || '')
          )
          .storeUint(0, 1)
          .storeCoins(toNano(0.02))
          .storeUint(0, 1)
          .endCell();

        const tx = {
          validUntil: Math.floor(Date.now() / 1000) + 360,
          messages: [
            {
              address: jettonWalletAddress.toRawString(), // sender jetton wallet
              amount: toNano(0.05).toString(), // for commission fees, excess will be returned
              payload: body.toBoc().toString('base64'), // payload with jetton transfer body
            },
          ],
        };
        return await tonconnect.connector.sendTransaction(tx);
      } else {
        const tx = {
          validUntil: Math.floor(Date.now() / 1000) + 360,
          messages: [
            {
              address: Address.parse(walletReceiver).toRawString(),
              amount: toNano(sendedAmount).toString(),
            },
          ],
        };
        return await tonconnect.connector.sendTransaction(tx);
      }
    },
    [tonconnect.connector]
  );

  const withdrawSolana = useCallback(
    async (params: WithdrawParams) => {
      if (!solanaWallet?.adapter.publicKey) {
        throw new Error('No solana account');
      }

      const { sendedCurrency, walletReceiver, sendedAmount } = params;
      const transaction = new solana.Transaction();
      if (sendedCurrency.contract.type === 'NATIVE') {
        transaction.add(
          solana.SystemProgram.transfer({
            fromPubkey: solanaWallet.adapter.publicKey,
            toPubkey: new solana.PublicKey(walletReceiver),
            lamports: sendedAmount * Math.pow(10, sendedCurrency.decimals),
          })
        );
      } else {
        const tokenAddr = new solana.PublicKey(sendedCurrency.contract.address);
        const fromTokenAddress = slp.getAssociatedTokenAddressSync(
          tokenAddr,
          solanaWallet.adapter.publicKey
        );
        const toTokenAddress = slp.getAssociatedTokenAddressSync(
          tokenAddr,
          new solana.PublicKey(walletReceiver)
        );

        transaction.add(
          slp.createTransferInstruction(
            fromTokenAddress,
            toTokenAddress,
            solanaWallet.adapter.publicKey,
            sendedAmount * Math.pow(10, sendedCurrency.decimals)
          )
        );
      }
      const rpc = await getRPCByNetwork(params.network);
      const connection = new Connection(rpc.rpc, 'confirmed');

      // @ts-ignore
      if (params.network && window.backpack) {
        // @ts-ignore
        await window.backpack.connect({
          chainGenesisHash:
            params.network.chainId === '902'
              ? 'EAQLJCV2mh23BsK2P9oYpV5CHVLDNHTxY'
              : '5eykt4UsFv8P8NJdTREpY1vzqKqZKvdpKuc147dw2N9d',
        });
      }

      return await sendSolanaTransaction(transaction, connection);
    },
    [solanaWallet, sendSolanaTransaction]
  );

  const withdrawTron = useCallback(
    async (params: WithdrawParams) => {
      if (!tronAddress) {
        throw new Error('No tron address');
      }
      const { sendedCurrency, walletReceiver, sendedAmount } = params;
      tronWeb.setAddress(tronAddress);

      const isNativeToken = sendedCurrency.contract.type === 'NATIVE';

      if (isNativeToken) {
        const tx = tronWeb.transactionBuilder.sendTrx(
          walletReceiver,
          tronWeb.fromSun(sendedAmount),
          tronAddress
        );

        const signedTx = await signTransaction(tx.transaction as Transaction);

        if (!signedTx) {
          throw new Error('Transaction failed');
        }

        const txResult = await tronWeb.trx.sendRawTransaction(signedTx);

        const txId = txResult.txid;

        return await waitForTronTransaction(txId);
      } else {
        const tx = await tronWeb.transactionBuilder.triggerSmartContract(
          sendedCurrency.contract.address,
          'transfer(address,uint256)',
          {
            feeLimit: TRON_FEE_LIMIT,
            callValue: ZERO_TRX_IN_SUN_UNITS,
          },
          [
            { type: 'address', value: walletReceiver },
            {
              type: 'uint256',
              value: sendedAmount * 10 ** sendedCurrency.decimals,
            },
          ]
        );

        const signedTx = await signTransaction(tx.transaction as Transaction);
        if (!signedTx) {
          throw new Error('Transaction failed');
        }

        const txResult = await tronWeb.trx.sendRawTransaction(signedTx);

        if (txResult.code === 'CONTRACT_VALIDATE_ERROR') {
          throw new Error(tronWeb.toUtf8(txResult.message));
        }

        const txId = txResult.txid;

        return await waitForTronTransaction(txId);
      }
    },
    [tronAddress, signTransaction]
  );

  const withdrawFuel = useCallback(
    async (params: Required<WithdrawParams>) => {
      const amount = (
        params.sendedAmount *
        10 ** params.sendedCurrency.decimals
      ).toFixed(0);
      return await sendFuelTransaction(
        params.walletReceiver,
        amount,
        params.sendedCurrency.contract.address
      );
    },
    [sendFuelTransaction]
  );

  const withdrawBitcoin = useCallback(
    async (params: Required<WithdrawParams>) => {
      const amount = (
        params.sendedAmount *
        10 ** params.sendedCurrency.decimals
      ).toFixed(0);

      return await sendBitcoinTransaction(
        params.walletReceiver,
        BigInt(amount)
      );
    },
    [sendBitcoinTransaction]
  );

  const withdrawSui = useCallback(
    async (params: Required<WithdrawParams>) => {
      const amount = params.sendedAmount * 10 ** params.sendedCurrency.decimals;
      return await sendSuiTransaction(
        params.walletReceiver,
        amount,
        params.sendedCurrency.contract.address
      );
    },
    [sendSuiTransaction]
  );

  const withdraw = useCallback(
    async (params: WithdrawParams) => {
      const { sendedCurrency, walletReceiver, sendedAmount, network } = params;

      if (!sendedCurrency) {
        throw Error('No token provided');
      }
      if (!sendedAmount || +sendedAmount <= 0 || isNaN(+sendedAmount)) {
        throw Error('Invalid amount provided');
      }
      if (!walletReceiver) {
        throw Error('No address provided');
      }
      if (!network) {
        throw Error("Can't retrieve network");
      }

      try {
        if (
          network.network_type === NetworkTypes.EVM ||
          network.network_type === NetworkTypes.ZK_SYNC_ERA
        ) {
          return await withdrawEvm({
            sendedCurrency,
            walletReceiver,
            sendedAmount,
            network,
          });
        } else if (network.network_type === NetworkTypes.STARKNET) {
          return await withdrawStarknet({
            sendedCurrency,
            walletReceiver,
            sendedAmount,
            network,
          });
        } else if (network.network_type === NetworkTypes.TON) {
          return await withdrawTon({
            sendedCurrency,
            walletReceiver,
            sendedAmount,
            network,
          });
        } else if (network.network_type === NetworkTypes.SOLANA) {
          return await withdrawSolana({
            sendedCurrency,
            walletReceiver,
            sendedAmount,
            network,
          });
        } else if (network.network_type === NetworkTypes.TRON) {
          return await withdrawTron({
            sendedCurrency,
            walletReceiver,
            sendedAmount,
            network,
          });
        } else if (network.network_type === NetworkTypes.FUEL) {
          return await withdrawFuel({
            sendedCurrency,
            walletReceiver,
            sendedAmount,
            network,
          });
        } else if (network.network_type === NetworkTypes.BITCOIN) {
          return await withdrawBitcoin({
            sendedCurrency,
            walletReceiver,
            sendedAmount,
            network,
          });
        } else if (network.network_type === NetworkTypes.SUI) {
          return await withdrawSui({
            sendedCurrency,
            walletReceiver,
            sendedAmount,
            network,
          });
        }
      } catch (error) {
        logInGroup('useWithdraw', error);
        const parsedError = JSON.parse(JSON.stringify(error, null, 2));
        logInGroup('useWithdraw', parsedError);
        if (
          parsedError.code === 'TRANSACTION_REPLACED' &&
          parsedError?.replacement?.confirmations >= 1
        ) {
          return true;
        }
        setIsCancelled(true);
        if (
          (error as EthersError).code === 'ACTION_REJECTED' ||
          (error as EthersError).message === 'User abort'
        ) {
          throw Error('ACTION_REJECTED');
        }
        throw Error("Can't create transaction");
      }
    },
    [
      withdrawEvm,
      withdrawStarknet,
      withdrawTon,
      withdrawSolana,
      withdrawTron,
      withdrawFuel,
      withdrawBitcoin,
      withdrawSui,
      setIsCancelled,
    ]
  );

  return { withdraw };
};
