import { useMemo, useState } from 'react';
import { SwitchChainError } from 'viem';
import { useSwitchChain } from 'wagmi';
import { useWalletFrom } from '@/hooks/useWalletFrom';
import { ICurrency, ICurrencyWithPairs, INetwork } from '@/types/apiTypes';
import { UnsupportedChainDialog } from '@/components/UnsoportedChainDialog';
import { ConnectWalletModalWithDisabled } from '@/components/ConnectWalletModalWithDisabled';
import { NetworkTypes } from '@/providers/web3Provider';
import { useWallet as useTronWallet } from '@tronweb3/tronwallet-adapter-react-hooks';
import { chainIdNetworkMap as tronChainIdNetworkMap } from '@tronweb3/tronwallet-adapters';
import { useRPCStore } from '@/stores/rpc.store';

export type SubmitButtonProps = {
  title: string;
  isDisabled: boolean;
  onSubmit: () => void;
  networkFrom?: INetwork;
  currencyFrom?: ICurrency | ICurrencyWithPairs;
  isConfirm?: boolean;
};

export function SubmitButton({
  title,
  networkFrom,
  currencyFrom,
  isDisabled,
  isConfirm,
  onSubmit,
}: SubmitButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isUnsupportedDialogOpen, setIsUnsupportedDialogOpen] = useState(false);

  const { switchChainAsync } = useSwitchChain();
  const { isFromConnected, isWrongNetwork } = useWalletFrom(networkFrom);
  const { wallet } = useTronWallet();

  const RPCs = useRPCStore(s => s.RPCs);
  const rpcEndpoint = useMemo(
    () => RPCs.find(n => n.id === networkFrom?.id)?.rpc ?? '',
    [networkFrom, RPCs]
  );

  const switchNetworkHelper = async () => {
    try {
      switch (networkFrom?.network_type) {
        case NetworkTypes.TRON:
          if (wallet) {
            await wallet.adapter.switchChain(
              Object.keys(tronChainIdNetworkMap)?.[0]
            );
          }
          break;

        default:
          if (currencyFrom && networkFrom && switchChainAsync) {
            switchChainAsync({
              chainId: +networkFrom.chainId,
            });
          }
          break;
      }
    } catch (error) {
      console.error(error);
      if (error instanceof SwitchChainError) {
        setIsUnsupportedDialogOpen(true);
      }
    }
  };

  const handleSubmit = async () => {
    if (isWrongNetwork && isConfirm) {
      await switchNetworkHelper();
      return;
    }

    onSubmit();
  };

  return (
    <>
      {isFromConnected ? (
        <button
          className="btn btnSecondary w-100 margin-top btnGradient"
          onClick={handleSubmit}
          disabled={isDisabled}
        >
          {isWrongNetwork && isConfirm ? 'Switch Network' : title}
        </button>
      ) : (
        <button
          onClick={() => setIsOpen(true)}
          className="btn btnSecondary w-100 mt-3 btnGradient"
        >
          Connect Wallet
        </button>
      )}
      <ConnectWalletModalWithDisabled
        openModal={isOpen}
        setOpenModal={setIsOpen}
        networkType={networkFrom?.network_type}
      />
      <UnsupportedChainDialog
        isOpen={isUnsupportedDialogOpen}
        onOpenChange={setIsUnsupportedDialogOpen}
      />
    </>
  );
}
