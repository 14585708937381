export const getSolanaWalletIcon = (name: string, icon: string) => {
  const icons: Record<string, string> = {
    Phantom: 'phantom',
    MetaMask: 'metaMask',
    Trust: 'trust',
    Coin98: 'coin98',
    'Coinbase Wallet': 'coinbase',
    Nightly: 'nightly',
    Backpack: 'backpack',
  };

  return icons[name]
    ? require(`@/Assets/images/wallets/${icons[name]}.png`)
    : icon;
};
