import { getAccount, getAssociatedTokenAddress } from '@solana/spl-token';
import { Connection, PublicKey } from '@solana/web3.js';

export const MAIN_SOLANA_TOKEN_ADDRESS = '11111111111111111111111111111111';
export const ECLIPSE_NATIVE_TOKEN_ADDRESS =
  'So11111111111111111111111111111111111111112';

export const getSolanaBalance = async (
  account: string,
  rpc_url?: string,
  currencyAddress?: string,
  currencyDecimals?: number
) => {
  if (!rpc_url || !currencyAddress || !currencyDecimals) {
    return '0';
  }
  const connection = new Connection(rpc_url);
  const walletPubKey = new PublicKey(account);

  if (
    currencyAddress !== MAIN_SOLANA_TOKEN_ADDRESS &&
    currencyAddress !== ECLIPSE_NATIVE_TOKEN_ADDRESS
  ) {
    const tokenAccount = await getAssociatedTokenAddress(
      new PublicKey(currencyAddress),
      walletPubKey
    );
    const accountInfo = await getAccount(connection, tokenAccount);
    return (Number(accountInfo.amount) / 10 ** currencyDecimals).toString();
  }

  const balance = await connection.getBalance(walletPubKey);
  return (balance / 10 ** currencyDecimals).toString();
};
