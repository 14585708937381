import { useWallet } from '@solana/wallet-adapter-react';

import { getSolanaWalletIcon } from '@/solana/getSolanaWalletIcon';
import { OptionsProps } from '..';

export function SolanaOptions({ onOpenChange }: OptionsProps) {
  const SOLANA_ONLY_WALLETS = ['phantom', 'solflare', 'fractal'];

  const ECLIPSE_WALLETRS = ['backpack'];
  const { select, wallets } = useWallet();

  const solanaOnlyWallets = wallets.filter(w =>
    SOLANA_ONLY_WALLETS.includes(w.adapter.name.toLowerCase())
  );

  const eclipseWallets = wallets.filter(w =>
    ECLIPSE_WALLETRS.includes(w.adapter.name.toLowerCase())
  );

  return (
    <div className="connect-wallet--group">
      <div className="connect-wallet--group-heading">Solana wallets</div>
      <div className="connect-wallet--group-grid">
        {solanaOnlyWallets.map(wallet => (
          <button
            key={wallet.adapter.name}
            className="connect-wallet--group-item w-100"
            onClick={() => {
              onOpenChange(false);
              select(wallet.adapter.name);
            }}
          >
            <div style={{ borderRadius: '50%', backgroundColor: 'white' }}>
              <img
                src={getSolanaWalletIcon(
                  wallet.adapter.name,
                  wallet.adapter.icon
                )}
                style={{
                  height: '24px',
                  width: '24px',
                  borderRadius: '50%',
                }}
                alt={wallet.adapter.name}
              />
            </div>

            {wallet.adapter.name}
          </button>
        ))}
      </div>
      <div className="mt-4"></div>
      <div className="connect-wallet--group-heading">
        Solana & Eclipse wallets
      </div>
      <div className="connect-wallet--group-grid">
        {eclipseWallets.map(wallet => (
          <button
            key={wallet.adapter.name}
            className="connect-wallet--group-item w-100"
            onClick={() => {
              onOpenChange(false);
              select(wallet.adapter.name);
            }}
          >
            <div style={{ borderRadius: '50%', backgroundColor: 'white' }}>
              <img
                src={getSolanaWalletIcon(
                  wallet.adapter.name,
                  wallet.adapter.icon
                )}
                style={{
                  height: '24px',
                  width: '24px',
                  borderRadius: '50%',
                }}
                alt={wallet.adapter.name}
              />
            </div>

            {wallet.adapter.name}
          </button>
        ))}
        {eclipseWallets.length < 1 && (
          <button
            key="backpack"
            className="connect-wallet--group-item w-100"
            onClick={() => {
              window.open('https://backpack.app/', '_blank');
            }}
          >
            <div style={{ borderRadius: '50%', backgroundColor: 'white' }}>
              <img
                src={getSolanaWalletIcon(
                  'Backpack',
                  'https://backpack.app/favicon.ico'
                )}
                style={{
                  height: '24px',
                  width: '24px',
                  borderRadius: '50%',
                }}
                alt="Backpack"
              />
            </div>
            Backpack
          </button>
        )}
      </div>
    </div>
  );
}
